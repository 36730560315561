import { useQuery, QueryHookOptions } from '@apollo/client'
import { useAtom } from 'jotai'

import { ManagerPracticeBaseQueryDocument } from '@docpace/manager-graphql/dist/urql.types'
import { ManagerPracticeBaseQueryResultFragment, ManagerPracticeBaseQueryParams } from '@docpace/manager-graphql'
import { ctxPracticeDepartments, ctxPracticeId, ctxPracticeOverview, ctxPracticeProviders } from '@docpace/shared-react-atoms'
import moment, { Moment } from 'moment'
import { compact } from 'lodash'

export function useManagerPracticeBaseQuery(opts?: QueryHookOptions<ManagerPracticeBaseQueryResultFragment, Partial<ManagerPracticeBaseQueryParams>>){
    const queryResult
    = useQuery<ManagerPracticeBaseQueryResultFragment>(ManagerPracticeBaseQueryDocument, opts)
    return queryResult
}

interface UseContextManagerPracticeBaseQueryProps {
    dayStart?: Moment
    dayEnd?: Moment
    skip?: boolean
}

export function useContextManagerPracticeBaseQuery(props?: UseContextManagerPracticeBaseQueryProps){
    const {
        dayStart = moment().startOf('day'),
        dayEnd = moment().startOf('day'),
        skip
    } = props ?? {}
    const [ practiceId ] = useAtom(ctxPracticeId)
    const [ practice, setPractice ] = useAtom(ctxPracticeOverview)
    const [ departments, setDepartments ] = useAtom(ctxPracticeDepartments)
    const [ providers, setProviders ] = useAtom(ctxPracticeProviders)
    const managerBaseQueryResult = useManagerPracticeBaseQuery({
        variables: {
            practiceId,
            dayStart: dayStart.format('YYYY-MM-DD'),
            dayEnd: dayEnd.format('YYYY-MM-DD'),
        },
        skip: !practiceId || skip,
        onCompleted(data){
            setPractice(data?.practice ?? undefined)
            setProviders(compact(data?.providers?.nodes) ?? [])
            setDepartments(compact(data?.departments?.nodes) ?? [])
        }
    })

    return {
        practice,
        departments,
        providers,
        ...managerBaseQueryResult
    }
}
