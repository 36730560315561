import React, { FC, useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { compact, sortBy, filter, find } from 'lodash'

import { 
    useManagerBaseQuery,
    useContextManagerAdminBaseQuery,
    useContextManagerOverviewQuery,
    useContextManagerPracticeBaseQuery,
    useContextManagerBaseQuery,
} from '@docpace/manager-react-apollo'

import { 
    useUpdateActorManagerDashboardSettingsMutation,
    useCreateProviderDepartmentAutoTextPausesMutation
} from '@docpace/manager-react-apollo'
import { useFaro } from './useFaro'
import { useAtom } from 'jotai'
import { ctxManagerId } from '@docpace/shared-react-atoms'

interface UseManagerBaseProps {
    skip?: boolean
}

export const useManagerBase = (props?:UseManagerBaseProps) => {
    const { skip } = props ?? {}
    const [hasNetworkError, setHasNetworkError] = useState<boolean>(false)
    const [isInitialized, setIsInitialized] = useState<boolean>(false)
    const [isUpdating, setIsUpdating] = useState<boolean>(false)
    const [ managerId ] = useAtom(ctxManagerId)
    const { pushFaroEvent } = useFaro()
    useManagerBaseQuery({ // keep this information current w/ polling
        pollInterval: 1000 * 60 * 5, // every five minutes,
        skip
    })
    
    const { 
        practiceId, adminId, _adminId, tableauLinks, serverInfo, 
        loading, networkStatus: baseNetworkStatus
    } = useContextManagerBaseQuery()

    const { manager, loading: managerLoading } = useContextManagerOverviewQuery()
    const { admin, loading: adminIsLoading } = useContextManagerAdminBaseQuery()

    const {
        practice, providers, departments,
        loading: managerPracticeBaseQueryLoading,
        networkStatus: managerPracticeBaseQueryNetworkStatus,
        refetch: managerPracticeBaseQueryRefetch,
    } = useContextManagerPracticeBaseQuery()
   

    const managerBaseQueryOutput = useMemo(()=>{
        const _providers = compact(sortBy(providers ?? [], 'displayName'))

        const managerDashboardProviders = admin ? _providers
        : filter(
            compact(_providers),
            ({ providerId }) =>
                !!find(manager?.managerProviderPermissions?.nodes, {
                    providerId,
                })
        )

        return {
            practice,
            departments: compact(sortBy(departments, 'publicName')) ?? [],
            providers: _providers,
            enabledProviders: _providers?.filter(p=>!!p.fetchAppointmentsEnabled),
            managerDashboardProviders: compact(sortBy(managerDashboardProviders, 'displayName')) ?? [],
        }
    }, [ managerPracticeBaseQueryLoading ])

    const actor = admin?.adminActor ?? manager?.managerActor ?? undefined
    
    const isLoading =
        loading ||
        managerPracticeBaseQueryLoading ||
        managerLoading ||
        adminIsLoading ||
        (!admin && !manager)

    // const managerDashboardProvidersDisabledReminders = admin
    //   ? filter(providers, ( provider ) => { return provider.isTextReminderDisabledToday } )
    //   : filter(managerDashboardProviders,( provider ) => { return provider.isTextReminderDisabledToday } );

    // const managerDashboardProvidersEnabledReminders = admin
    //   ? filter(providers, ( provider ) => { return !provider.isTextReminderDisabledToday } )
    //   : filter(managerDashboardProviders,( provider ) => { return !provider.isTextReminderDisabledToday } );

    useEffect(() => {
        if (
            baseNetworkStatus > 3 &&
            managerPracticeBaseQueryNetworkStatus > 3 &&
            !practice
        ) {
            setHasNetworkError(true)
        } else if (
            baseNetworkStatus > 3 &&
            managerPracticeBaseQueryNetworkStatus > 3 &&
            (!!admin || !!manager)
        ) {
            setHasNetworkError(false)
            setIsInitialized(true)
        } else {
            setHasNetworkError(false)
        }
    }, [
        practiceId,
        baseNetworkStatus,
        managerPracticeBaseQueryNetworkStatus,
        isLoading,
        practice,
        admin,
        manager
    ])
    
    const managerDashboardSettings =
        admin?.adminActor?.managerDashboardSettings ??
        manager?.managerActor?.managerDashboardSettings

    const [updateManagerDashboardSettingsMutation] =
        useUpdateActorManagerDashboardSettingsMutation({})

    const [createProviderDepartmentAutoTextPausesMutation] =
        useCreateProviderDepartmentAutoTextPausesMutation({})

    const createProviderDepartmentAutoTextPauses = async (
        providerIds: string[], practiceId: string
    ) => {
        setIsUpdating(true)
        pushFaroEvent('manager-create-department-auto-text-pause')
        await createProviderDepartmentAutoTextPausesMutation({
            variables: {
                providerIds: providerIds,
                practiceId,
            },
        })
        setIsUpdating(false)
    }

    const updateManagerDashboardSettings = async (key: string, value: any) => {
        if (actor) {
            setIsUpdating(true)
            pushFaroEvent('manager-update-dashboard-setting', { key, value })
            await updateManagerDashboardSettingsMutation({
                variables: {
                    actorId: actor.actorId ?? 0,
                    settings: {
                        ...actor?.managerDashboardSettings,
                        [key]: value,
                    },
                },
                optimisticResponse: {
                    updateActor: {
                        actor: {
                            ...actor,
                            managerDashboardSettings: {
                                ...actor?.managerDashboardSettings,
                                [key]: value,
                            },
                            // this following lines force an immediate render rather than waiting for the server response
                            ...(key === 'appointmentSortDirection'
                                ? { managerAppointmentSortDirection: value }
                                : {}),
                            ...(key === 'notifyWaitForIntakeDelays'
                                ? { notifyWaitForIntakeDelays: value }
                                : {}),
                            ...(key === 'notifyWaitForDoctorDelays'
                                ? { notifyWaitForDoctorDelays: value }
                                : {}),
                            ...(key === 'notifyWaitForIntakeDelaysAfterMinutes'
                                ? {
                                      notifyWaitForIntakeDelaysAfterMinutes:
                                          value,
                                  }
                                : {}),
                            ...(key === 'notifyWaitForDoctorDelaysAfterMinutes'
                                ? {
                                      notifyWaitForDoctorDelaysAfterMinutes:
                                          value,
                                  }
                                : {}),
                        },
                    },
                },
            })
            setIsUpdating(false)
        }
    }

    const managerCanAccessTableau = manager?.tableauManagerId && (
        manager?.canAccessPatientFlowReport 
        || manager?.canAccessMonthlyReport 
        || manager?.canAccessInsights
    )

    const insightsSettings = {
        managerCanAccessTableau,
        showInsightsLinks: !!admin || managerCanAccessTableau, // show the links even if they cannot access them
        insightsRequiresUpgrade: !admin && (managerCanAccessTableau && !manager?.canAccessInsights),
        showPatientFlowReport: !!admin || (managerCanAccessTableau &&  manager?.canAccessPatientFlowReport),
        showMonthlyReport: !!admin || (managerCanAccessTableau &&  manager?.canAccessMonthlyReport),
    }
    
    const displaySettings = {
        showCheckoutTable: !!admin || manager?.canAccessCheckoutTable,
        eulaAgreementRequired:
            !isLoading &&
            !admin &&
            !!manager &&
            (!manager?.eulaTimestamp ||
                moment(manager?.eulaTimestamp).isBefore('2021-11-10')),
    }

    return {
        practiceId,
        createProviderDepartmentAutoTextPauses,
        managerPracticeBaseQueryRefetch: ()=>managerPracticeBaseQueryRefetch().then(()=>{}),
        manager,
        managerId,
        admin,
        adminId,
        _adminId,
        actor,
        tableauLinks,
        isLoading,
        isUpdating,
        hasNetworkError,
        managerDashboardSettings,
        isInitialized,
        isProd: serverInfo?.isProd,
        updateManagerDashboardSettings,
        ...managerBaseQueryOutput,
        ...insightsSettings,
        ...displaySettings,
    }
}
