import moment from 'moment'

import {
    refreshAccessTokenFromAPI,
} from '@docpace/shared-react-utils/sessionUtils'
import { isHttps } from '@docpace/shared-react-utils/windowUtils'
import { useRouter } from 'next/router'

const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token'
const ACCESS_TOKEN_STORAGE_KEY = 'access_token'
const ACCESS_TOKEN_EXPIRATION_KEY = 'access_token_expires_at'

export function makeServerHostname(envHost, port){
    return (
        envHost ??
        ((typeof window !== 'undefined' && window.location.hostname === 'localhost') ? `localhost:${port}` : null) ??
        (typeof window !== 'undefined' ? window.location.hostname : '')
    )
}

export function isTokenValidOrUndefined(){
    const { accessToken, refreshToken, accessTokenExpiresAt } = initializeAuthState()
    if (
        !refreshToken ||
        (accessToken && refreshToken && accessTokenExpiresAt &&
            moment() // refresh only if the access token is set to expire within the next 5 minutes
                .add(5 * 60, 'seconds')
                .isBefore(moment(accessTokenExpiresAt)))
    )
        return true
    return false
}

// export function fetchAccessToken(serverHostname: string){
//     console.log('fetchingAccessToken')
//     return refreshAccessTokenFromAPI(serverHostname)
// }

export function initializeAuthState() {
    const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_STORAGE_KEY)
    const accessToken = sessionStorage.getItem(ACCESS_TOKEN_STORAGE_KEY)
    const accessTokenExpiresAt = sessionStorage.getItem(
        ACCESS_TOKEN_EXPIRATION_KEY
    )
    return { accessToken, refreshToken, accessTokenExpiresAt };
}

export function isAuthPage() {
    return window?.location?.pathname.split('/')[1] == 'auth'
}

export function isIndexPage() {
    const location = useRouter()
    return location?.pathname === '/'
}

export function makeGraphqlApiHttpUrl(serverHostname){
    return `http${isHttps ? 's' : ''}://${serverHostname}/api/graphql`

}

export function makeGraphqlApiWsUrl(serverHostname){
    return `ws${isHttps ? 's' : ''}://${serverHostname}/api/graphql`

}