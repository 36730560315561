import { useQuery, QueryHookOptions } from '@apollo/client'
import { useAtom } from 'jotai'

import { ManagerOverviewQueryDocument } from '@docpace/manager-graphql/dist/urql.types'
import { ManagerOverviewQueryResultFragment, ManagerOverviewQueryParams } from '@docpace/manager-graphql'
import { ctxManager, ctxManagerId } from '@docpace/shared-react-atoms'

export function useManagerOverviewQuery(opts?: QueryHookOptions<ManagerOverviewQueryResultFragment, Partial<ManagerOverviewQueryParams>>){
    return useQuery<ManagerOverviewQueryResultFragment>(ManagerOverviewQueryDocument, opts)
}

export function useContextManagerOverviewQuery(){
    const [ managerId ] = useAtom(ctxManagerId)
    const [ , setContextManager ] = useAtom(ctxManager)
    const queryResult = useManagerOverviewQuery({
        variables: { managerId },
        skip: !managerId,
        onCompleted: ({ manager })=>{
            setContextManager(manager ?? undefined)
        }
    })

    return {
        ...queryResult,
        manager: queryResult?.data?.manager
    }
}