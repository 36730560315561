import { onError } from '@apollo/client/link/error'
import { setSessionData } from '@docpace/shared-react-utils/sessionUtils'
import { isAuthPage } from '@docpace/shared-ts-utils/clientUtils'

interface MakeApolloErrorLinkProps {}

export const makeApolloErrorLink = ({}: MakeApolloErrorLinkProps = {}) =>
    onError((r) => {
        const { graphQLErrors, networkError, operation, forward } = r
        if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) => {
                console.log(
                    `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                )
            })
        }
        if (networkError) {
            if(networkError.message?.includes('Error: Socket closed with event 4500')) return;
            console.log(`[Network error]: ${networkError}`)
            if (r.networkError?.['statusCode'] === 401) {
                if (isAuthPage()) {
                    return
                } else {
                    setSessionData(null).then(() => {})
                    console.log('401 detected - redirecting..')
                    window.location.replace('/auth')
                }
            }
        }
    })
