import { useQuery, QueryHookOptions } from '@apollo/client'
import { ManagerAdminBaseQueryDocument } from '@docpace/manager-graphql/dist/urql.types'
import { ManagerAdminBaseQueryResultFragment, ManagerAdminBaseQueryParams } from '@docpace/manager-graphql'
import { useAtom } from 'jotai'
import { ctxAdmin, ctxAdminId } from '@docpace/shared-react-atoms'

export function useManagerAdminBaseQuery(opts?: QueryHookOptions<ManagerAdminBaseQueryResultFragment, Partial<ManagerAdminBaseQueryParams>>){
    return useQuery<ManagerAdminBaseQueryResultFragment>(ManagerAdminBaseQueryDocument, opts)
}

export function useContextManagerAdminBaseQuery(){
    const [ adminId ] = useAtom(ctxAdminId)
    const [ , setContextAdmin ] = useAtom(ctxAdmin)
    const queryResult = useManagerAdminBaseQuery({
        variables: { adminId },
        skip: !adminId,
        onCompleted: ({ admin })=>{
            setContextAdmin(admin ?? undefined)
        }
    })

    return {
        ...queryResult,
        admin: queryResult?.data?.admin
    }
}